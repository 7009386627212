import constants from '../Utils/constants';
const initialState = {
  isSubscriptionLoading: false,
  isUpdateTrialEndLoading: false,
  isGetInvoicesLoading: false,
  isInvoiceByIdLoading: false,
  susbcriptionData: [],
  invoicesData: [],
  Subscription: {},
  Invoice: {},
};

export const subscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        isSubscriptionLoading: true,
      };
    case constants.GET_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isSubscriptionLoading: false,
        susbcriptionData: action.payload,
      };
    case constants.GET_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        isSubscriptionLoading: false,
      };
    case constants.UPDATE_TRIAL_END_REQUEST:
      return {
        ...state,
        isUpdateTrialEndLoading: true,
      };
    case constants.UPDATE_TRIAL_END_SUCCESS:
      return {
        ...state,
        isUpdateTrialEndLoading: false,
      };
    case constants.UPDATE_TRIAL_END_FAILURE:
      return {
        ...state,
        isUpdateTrialEndLoading: false,
      };
    case constants.GET_INVOICES_REQUEST:
      return {
        ...state,
        isGetInvoicesLoading: true,
      };
    case constants.GET_INVOICES_SUCCESS:
      return {
        ...state,
        isGetInvoicesLoading: false,
        invoicesData: action.payload,
      };
    case constants.GET_INVOICES_FAILURE:
      return {
        ...state,
        isGetInvoicesLoading: false,
      };
    case constants.GET_INVOICE_BY_ID_REQUEST:
      return {
        ...state,
        isInvoiceByIdLoading: true,
      };
    case constants.GET_INVOICE_BY_ID_SUCCESS:
      return {
        ...state,
        isInvoiceByIdLoading: false,
        Invoice: action?.payload,
      };
    case constants.GET_INVOICE_BY_ID_FAILURE:
      return {
        ...state,
        isInvoiceByIdLoading: false,
      };
    case constants.RESET_APP_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
