import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Pagination,
  Typography,
  Spin,
  Breadcrumb,
  Button,
  Modal,
} from 'antd';
import _, { isEmpty } from 'lodash';
import '../CustomTable/CustomTable.css';
import './Subscription.css';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from '../CustomTable/CustomTable';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getInvoiceById,
  getInvoices,
  getSubscription,
  getSubscriptionBillingHistory,
  updateTrialEnd,
} from '../../Actions/SubscriptionAction';
import moment from 'moment-timezone';
import {
  convertEpochToDate,
  getCurrentDayAndDate,
} from '../CommonLogics/CommonMethods';
import currentPlanRightTick from '../../assests/currentPlanRightTick.svg';

const Subscription = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    restaurant,
    isSubscriptionLoading,
    susbcriptionData,
    invoicesData,
    isGetInvoicesLoading,
    isInvoiceByIdLoading,
    Invoice,
  } = useSelector((state) => {
    const { restaurant } = state.restaurant;
    const {
      susbcriptionData,
      isSubscriptionLoading,
      invoicesData,
      isGetInvoicesLoading,
      isInvoiceByIdLoading,
      Invoice,
    } = state.subscription;
    return {
      restaurant,
      isSubscriptionLoading,
      susbcriptionData,
      invoicesData,
      isGetInvoicesLoading,
      isInvoiceByIdLoading,
      Invoice,
    };
  });

  const [currentPage, setCurrentPage] = useState(1);
  const { orgId } = useParams();
  const getCurrentdate = getCurrentDayAndDate('date');
  const organizationTimeZone =
    restaurant?.organizations_detail?.organization_time_zone;
  const organizationCurrencySymbol =
    restaurant?.organizations_detail?.default_currency_symbol;

  const onGoingSubscription = Array.isArray(susbcriptionData)
    ? susbcriptionData?.filter((subscription) => {
        if (subscription?.status === 'in_trial') {
          const trialStartDate = moment(
            convertEpochToDate(subscription?.trial_start, 'DD-MM-YYYY'),
            'DD-MM-YYYY'
          );
          const trialEndDate = moment(
            convertEpochToDate(subscription?.trial_end, 'DD-MM-YYYY'),
            'DD-MM-YYYY'
          );

          const isOngoingSubs = moment(getCurrentdate, 'DD-MM-YYYY').isBetween(
            trialStartDate,
            trialEndDate,
            'days',
            '[]'
          );

          return isOngoingSubs;
        } else {
          return subscription?.status === 'active';
        }
      })?.[0] ?? {}
    : {};

  useEffect(() => {
    dispatch(getSubscription(successCallback));
  }, []);

  const successCallback = (data) => {
    data?.filter((subscription) => {
      if (
        subscription?.status === 'active' ||
        subscription?.status === 'non_renewing'
      ) {
        dispatch(getInvoices(`?subscription_id=${subscription?.id}`));
      }
    });
  };

  const handleCloseRestaurant = () => {
    navigate('/restaurants');
    dispatch({
      type: 'GET_SUBSCRIPTION_SUCCESS',
      payload: '',
    });
    dispatch({
      type: 'GET_INVOICES_SUCCESS',
      payload: '',
    });
    dispatch({
      type: 'GET_SELECTED_RESTAURANT_ID',
      payload: '',
    });
  };

  const handleCloseOrganization = () => {
    dispatch({
      type: 'GET_SUBSCRIPTION_SUCCESS',
      payload: '',
    });
    dispatch({
      type: 'GET_INVOICES_SUCCESS',
      payload: '',
    });
    navigate(`/restaurants/${orgId}`);
  };

  const handleDownload = (Id) => {
    dispatch(getInvoiceById(`?invoice_id=${Id}`, successCallbackInvoice));
  };

  const successCallbackInvoice = (data) => {
    const url = data.download_url;
    if (url) {
      const link = document.createElement('a');
      link.href = url;
      link.download = 'invoice.pdf';
      link.click();
    }
  };

  const Columns = [
    {
      title: 'Payee Name/Number',
      dataIndex: 'payee_number',
      width: '10%',
      render: (id, { payee_number, payee_name }) => {
        return (
          <Row>
            <Col style={{ cursor: 'pointer' }}>
              <Typography className='queueActivityTextWrap'>
                {payee_number}
              </Typography>
              <Typography className='queueActivityTextWrap'>
                {payee_name}
              </Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Bill number',
      dataIndex: 'bill_number',
      width: '5%',
      render: (bill_number) => {
        return (
          <Row>
            <Col>
              <Typography>#{bill_number}</Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Bill Date',
      dataIndex: 'bill_date',
      width: '6%',
      render: (bill_date) => {
        return (
          <Row>
            <Col>
              <Typography className='queueActivityTextWrap'>
                {bill_date}
              </Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Plan Details',
      dataIndex: 'plan_detail',
      width: '6%',
      render: (plan_detail) => {
        return (
          <Row>
            <Col>
              <Typography className='queueActivityTextWrap'>
                {plan_detail}
              </Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Plan Duration',
      dataIndex: 'plan_duration',
      width: '10%',
      render: (plan_duration) => {
        return (
          <Row>
            <Col>
              <Typography className='queueActivityTextWrap'>
                {plan_duration}
              </Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Payment status',
      dataIndex: 'payment_status',
      width: '6%',
      render: (payment_status) => {
        return (
          <Row>
            <Col>
              <Typography>
                {payment_status.charAt(0).toUpperCase() +
                  payment_status?.slice(1)}
              </Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      width: '4%',
      render: (amount) => {
        return (
          <Row>
            <Col>
              <Typography className='queueActivityTextWrap'>
                {amount}
              </Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      dataIndex: 'bill_number',
      width: '2%',
      render: (bill_number) => {
        return (
          <Row>
            <Col>
              <Typography
                className='centerItem'
                onClick={() => handleDownload(bill_number)}
              >
                Download
              </Typography>
            </Col>
          </Row>
        );
      },
    },
  ];

  return (
    <Spin
      spinning={
        isSubscriptionLoading || isInvoiceByIdLoading || isGetInvoicesLoading
      }
    >
      <Row gutter={[24, 24]}>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Row gutter={[24, 24]}>
            <Col xl={23} lg={23} md={23} sm={23} xs={23}>
              <Breadcrumb separator='>'>
                <Breadcrumb.Item
                  onClick={() => handleCloseRestaurant()}
                  className='breadcrumRestaurant'
                >
                  Restaurants
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  onClick={() => handleCloseOrganization()}
                  className='breadcrumRestaurant'
                >
                  {restaurant?.name}
                </Breadcrumb.Item>
                <Breadcrumb.Item className='breadcrumUsers'>
                  Subscription
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            <Col span={14}>
              <Typography className='heading'>Subscription</Typography>
            </Col>
            <Col span={24}>
              <Typography className='headingText'>Current Plan</Typography>
            </Col>
            <Col span={12}>
              <CurrentSubscribePlan
                currentPlan={onGoingSubscription}
                organizationCurrencySymbol={organizationCurrencySymbol}
                organizationTimeZone={organizationTimeZone}
              />
            </Col>

            <Col span={24}>
              <Typography className='headingText'>Billing History</Typography>
            </Col>
            <Col span={24}>
              <CustomTable columns={Columns} data={invoicesData} />
              {invoicesData && invoicesData.length > 0 ? (
                <Col span={24}>
                  <Row justify='center' style={{ margin: 30 }}>
                    <Pagination
                      total={invoicesData?.length}
                      onChange={(e) => setCurrentPage(e)}
                      responsive={true}
                      size='large'
                      current={currentPage}
                      showSizeChanger={false}
                    />
                  </Row>
                </Col>
              ) : (
                ''
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Spin>
  );
};

const CurrentSubscribePlan = (props) => {
  const { currentPlan, organizationCurrencySymbol, organizationTimeZone } =
    props;
  const [isModal, setIsModal] = useState(false);
  const dispatch = useDispatch();
  const currentDate = getCurrentDayAndDate('date');
  const subscriptionAmount = currentPlan?.amount / 100;
  const subscriptionPlanPeriod = currentPlan?.billing_period_unit;
  const endTime = convertEpochToDate(
    currentPlan?.status === 'active'
      ? currentPlan?.current_term_end
      : currentPlan?.trial_end,
    'DD-MM-YYYY'
  );
  const startTime = convertEpochToDate(
    currentPlan?.status === 'active'
      ? currentPlan?.current_term_start
      : currentPlan?.trial_start,
    'DD MMMM, YYYY'
  );

  const end = moment(endTime, 'DD-MM-YYYY');
  const current = moment(currentDate, 'DD-MM-YYYY');
  const remainingDays = end.diff(current, 'days');

  const updateTrialEndDate =
    currentPlan &&
    moment
      .unix(currentPlan?.trial_end)
      .tz(organizationTimeZone)
      .add(30, 'days')
      .unix();

  const restartTrial = () => {
    const data = {
      subscription_id: currentPlan?.id,
      trial_end_date: updateTrialEndDate,
    };
    dispatch(updateTrialEnd(data, successCalllback));
  };

  const successCalllback = () => {
    setIsModal(false);
    dispatch(getSubscription());
  };

  return (
    <Row>
      <Col span={24}>
        <div className='currentPlanContainer'>
          <div className='currentPlanRightTick'>
            <img src={currentPlanRightTick} />
          </div>
          {isEmpty(currentPlan) ? (
            <Row gutter={[0, 20]}>
              <Col span={24}>
                <Row gutter={[0, 20]}>
                  <Col span={24}>
                    <Typography className='currentPlanName'>
                      No subscribed plan
                    </Typography>
                  </Col>
                  <Col span={24}>
                    <Typography className='currentPlanDaysRemaining'>
                      Subscribe to a plan now, to use PREST system.
                    </Typography>
                  </Col>
                </Row>
              </Col>
            </Row>
          ) : (
            <Row gutter={[0, 20]}>
              <Col span={24}>
                <Row>
                  <Col span={16}>
                    <Row gutter={[0, 10]}>
                      <Col span={24}>
                        <Typography className='currentPlanName'>
                          {currentPlan?.status === 'in_trial'
                            ? currentPlan?.meta_data?.plan_name || 'Free Trial'
                            : currentPlan?.meta_data?.plan_name}
                        </Typography>
                      </Col>
                      <Col span={24}>
                        <Typography className='currentPlanDaysRemaining'>
                          {remainingDays} days remaining
                        </Typography>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={8}>
                    <Row justify='end'>
                      <Col>
                        <Typography className='currentPlanAmount'>
                          {organizationCurrencySymbol}
                          {currentPlan?.status === 'in_trial'
                            ? '0'
                            : subscriptionAmount}
                          <span className='currentPlanbillingPeriod'>
                            /{subscriptionPlanPeriod}
                          </span>
                        </Typography>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row>
                  <Col span={24}>
                    <Row gutter={[20, 0]}>
                      <Col>
                        {currentPlan?.status === 'in_trial' ? (
                          <Button
                            className={
                              remainingDays
                                ? 'currentPlanButtonOpacity'
                                : 'currentPlanButton'
                            }
                            onClick={() => setIsModal(true)}
                            disabled={remainingDays !== 0}
                          >
                            Restart Trial
                          </Button>
                        ) : (
                          ''
                          // <Button
                          //   className='currentPlanButton'
                          //   // disabled={remainingDaysForTrial !== 0}
                          // >
                          //   Send Invoice
                          // </Button>
                        )}
                      </Col>
                      <Col
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Typography className='currentPlanStarted'>
                          started on {startTime}
                        </Typography>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </div>
      </Col>
      <Modal
        title={
          <>
            <Typography className='modalTitle'>Restart Free Trial</Typography>
            <Typography className='modalDescription'>
              Do you really want to restart the trial of this Organization?
            </Typography>
          </>
        }
        centered
        width={380}
        closable={false}
        open={isModal}
        footer={
          <Row gutter={[20, 20]} justify='center'>
            <Col>
              <Button
                size='large'
                key='cancel'
                onClick={() => setIsModal(false)}
                className='cancelButton'
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                size='large'
                key='submit'
                onClick={() => restartTrial()}
                className='orderButton'
              >
                Confirm
              </Button>
            </Col>
          </Row>
        }
      ></Modal>
    </Row>
  );
};

export default Subscription;
