import constants from '../Utils/constants';
const initialState = {
  isCustomerLoading: false,
  isCustomerSurveysLoading: false,
  isCustomerOrdersLoading: false,
  isCustomerQueueActivityLoading: false,
  allCustomers: [],
  allCustomerOrders: [],
  allCustomerSurveys: [],
  allCustomerQueueActivity: [],
  Customer: {},
};

export const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_CUSTOMERS_REQUEST:
      return {
        ...state,
        isCustomerLoading: true,
      };
    case constants.GET_CUSTOMERS_SUCCESS:
      return {
        ...state,
        isCustomerLoading: false,
        allCustomers: action.payload,
      };
    case constants.GET_CUSTOMERS_FAILURE:
      return {
        ...state,
        isCustomerLoading: false,
      };
    case constants.GET_CUSTOMER_BY_ID_REQUEST:
      return {
        ...state,
        isCustomerLoading: true,
      };
    case constants.GET_CUSTOMER_BY_ID_SUCCESS:
      return {
        ...state,
        isCustomerLoading: false,
        Customer: action?.payload,
      };
    case constants.GET_CUSTOMER_BY_ID_FAILURE:
      return {
        ...state,
        isCustomerLoading: false,
      };
    case constants.GET_CUSTOMER_ORDERS_REQUEST:
      return {
        ...state,
        isCustomerOrdersLoading: true,
      };
    case constants.GET_CUSTOMER_ORDERS_SUCCESS:
      return {
        ...state,
        isCustomerOrdersLoading: false,
        allCustomerOrders: action.payload,
      };
    case constants.GET_CUSTOMER_ORDERS_FAILURE:
      return {
        ...state,
        isCustomerOrdersLoading: false,
      };
    case constants.GET_CUSTOMER_SURVEYS_REQUEST:
      return {
        ...state,
        isCustomerSurveysLoading: true,
      };
    case constants.GET_CUSTOMER_SURVEYS_SUCCESS:
      return {
        ...state,
        isCustomerSurveysLoading: false,
        allCustomerSurveys: action.payload,
      };
    case constants.GET_CUSTOMER_SURVEYS_FAILURE:
      return {
        ...state,
        isCustomerQueueActivityLoading: false,
      };
    case constants.GET_CUSTOMER_QUEUE_ACTIVITY_REQUEST:
      return {
        ...state,
        isCustomerQueueActivityLoading: true,
      };
    case constants.GET_CUSTOMER_QUEUE_ACTIVITY_SUCCESS:
      return {
        ...state,
        isCustomerQueueActivityLoading: false,
        allCustomerQueueActivity: action.payload,
      };
    case constants.GET_CUSTOMER_QUEUE_ACTIVITY_FAILURE:
      return {
        ...state,
        isCustomerQueueActivityLoading: false,
      };
    case constants.RESET_APP_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
