import API from '../Utils/API';

export const getSubscription = (successCallback) => (dispatch) => {
  dispatch({ type: 'GET_SUBSCRIPTION_REQUEST' });
  API.get(`api/organization_subscriptions`, successCallback)
    .then((response) => {
      dispatch({ type: 'GET_SUBSCRIPTION_SUCCESS', payload: response.data });
      successCallback(response?.data);
    })
    .catch((error) => {
      try {
        dispatch({
          type: 'GET_SUBSCRIPTION_FAILURE',
          payload: error.response.data,
        });
      } catch {}
    });
};

export const updateTrialEnd = (data, successUpdateCallBack) => (dispatch) => {
  dispatch({ type: 'UPDATE_TRIAL_END_REQUEST' });
  API.put(`api/update_subscription_trial_end`, data)
    .then((response) => {
      dispatch({ type: 'UPDATE_TRIAL_END_SUCCESS', payload: response.data });
      successUpdateCallBack && successUpdateCallBack();
    })
    .catch((error) => {
      dispatch({
        type: 'UPDATE_TRIAL_END_FAILURE',
        payload: error.response.data,
      });
    });
};

export const getInvoices = (params, successCallback) => (dispatch) => {
  dispatch({ type: 'GET_INVOICES_REQUEST' });
  API.get(`api/get_invoices${params}`, successCallback)
    .then((response) => {
      dispatch({
        type: 'GET_INVOICES_SUCCESS',
        payload: response.data,
      });
      successCallback(response?.data);
    })
    .catch((error) => {
      try {
        dispatch({
          type: 'GET_INVOICES_FAILURE',
          payload: error.response.data,
        });
      } catch {}
    });
};

export const getInvoiceById = (Id, successCallbackInvoice) => (dispatch) => {
  dispatch({ type: 'GET_INVOICE_BY_ID_REQUEST' });
  API.get(`api/invoice_by_id${Id}`)
    .then((response) => {
      dispatch({
        type: 'GET_INVOICE_BY_ID_SUCCESS',
        payload: response.data,
      });
      successCallbackInvoice && successCallbackInvoice(response.data);
    })
    .catch((error) => {
      dispatch({
        type: 'GET_INVOICE_BY_ID_FAILURE',
        payload: error?.response.data,
      });
    });
};
