import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Row,
  Col,
  Typography,
  Spin,
  Breadcrumb,
  Pagination,
  Drawer,
  Tabs,
  Divider,
  Checkbox,
  DatePicker,
  Rate,
  Radio,
  Select,
  Input,
  Tooltip,
  Timeline,
} from 'antd';
import '../Restaurant/Restaurant.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import {
  FaCaretDown,
  FaCircle,
  FaIndianRupeeSign,
  FaRegCircleUser,
} from 'react-icons/fa6';
import foodRestaurant from '../../assests/foodRestaurant.svg';
import takeAway from '../../assests/takeAway.svg';
import CustomTable from '../CustomTable/CustomTable';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {
  getCustomerOrders,
  getCustomerQueueActivity,
  getCustomerSurveys,
} from '../../Actions/CustomerAction';
import { getSurveyAttemptResults } from '../../Actions/surveyAction';
import Back from '../../assests/Back.svg';
import TextArea from 'antd/lib/input/TextArea';
import DateRangeSelector from '../../Utils/DateRangeSelector';
import moment from 'moment';
import { MdAccessTimeFilled, MdTableRestaurant } from 'react-icons/md';
import { IoMdPerson } from 'react-icons/io';
import kidsChair from '../../assests/kidsChair.svg';
import bbqGrill from '../../assests/bbqGrill.svg';
import disabledSeat from '../../assests/disabledSeat.svg';
import SearchComponent from '../../Utils/SearchComponent';

dayjs.extend(customParseFormat);

const CustomerDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    Customer,
    allCustomerOrders,
    allCustomerSurveys,
    allCustomerQueueActivity,
    restaurant,
    isCustomerLoading,
    allSurveyAttemptResult,
    isCustomerSurveysLoading,
    isCustomerOrdersLoading,
    isCustomerQueueActivityLoading,
  } = useSelector((state) => {
    const { restaurant } = state.restaurant;
    const { allSurveyAttemptResult } = state.survey;
    const {
      Customer,
      allCustomerOrders,
      allCustomerSurveys,
      allCustomerQueueActivity,
      isCustomerLoading,
      isCustomerSurveysLoading,
      isCustomerOrdersLoading,
      isCustomerQueueActivityLoading,
    } = state.customer;
    return {
      Customer,
      allCustomerOrders,
      allCustomerSurveys,
      restaurant,
      isCustomerLoading,
      allSurveyAttemptResult,
      allCustomerQueueActivity,
      isCustomerSurveysLoading,
      isCustomerOrdersLoading,
      isCustomerQueueActivityLoading,
    };
  });
  const [isViewAddressesOpen, setIsViewAddressesOpen] = useState(false);
  const [allOrders, setAllOrders] = useState([]);
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [isTabChanged, setIsTabChanged] = useState('orders');
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });
  const [isCheckResponseOpen, setIsCheckResponseOpen] = useState(false);
  const [isQueueActivityOpen, setIsQueueActivityOpen] = useState(false);
  const [queueActivityData, setQueueActivityData] = useState('');
  const [orderPage, setOrderPage] = useState(1);
  const [surveyPage, setSurveyPage] = useState(1);
  const [queueActivityPage, setQueueActivityPage] = useState(1);
  const [orderId, setOrderId] = useState('');

  const { orgId, customerId } = useParams();
  const { length } = allCustomerOrders?.customer_orders || {};
  const readOnlyStyle = { pointerEvents: 'none' };

  const parseDate = (dateStr) => {
    const [day, month, year] = dateStr?.split('-').map(Number);
    return new Date(year, month - 1, day);
  };
  const organizationTimezone =
    restaurant?.organizations_detail?.organization_time_zone;

  const organziationCurrencySymbol =
    restaurant?.organizations_detail?.default_currency_symbol;

  useEffect(() => {
    if (isTabChanged === 'orders') {
      dispatch(
        getCustomerOrders(
          `api/customer_orders?page=${orderPage}&customer_id=${customerId}`
        )
      );
    } else if (isTabChanged === 'surveys') {
      dispatch(
        getCustomerSurveys(
          `api/customer_surveys?page=${surveyPage}&customer_id=${customerId}`
        )
      );
    } else if (isTabChanged === 'queueActivity') {
      dispatch(
        getCustomerQueueActivity(
          `?page=${queueActivityPage}&customer_id=${customerId}`
        )
      );
    }
  }, [isTabChanged, orderPage, surveyPage, queueActivityPage]);

  useEffect(() => {
    if (allCustomerOrders?.customer_orders?.length > 0) {
      setAllOrders(allCustomerOrders?.customer_orders);
    }
  }, [allCustomerOrders]);

  const formatDate = (date) => {
    return date.toLocaleDateString('en-GB').replace(/\//g, '-');
  };

  const handleSelect = (ranges) => {
    const { startDate, endDate } = ranges.selection;
    setSelectionRange({ startDate, endDate, key: 'selection' });
    setIsPickerOpen(true);
    if (isTabChanged === 'orders') {
      filterData(startDate, endDate);
    } else if (isTabChanged === 'surveys') {
      dispatch(
        getCustomerSurveys(
          `api/customer_surveys?page=${surveyPage}&customer_id=${customerId}&from_date=${formatDate(
            ranges.selection.startDate
          )}&to_date=${formatDate(ranges.selection.endDate)}`
        )
      );
    }
  };

  const filterData = (startDate, endDate) => {
    const filtered = allCustomerOrders?.customer_orders?.filter((item) => {
      const itemDate = parseDate(item.order_created_date_time.created_date);
      return itemDate >= startDate && itemDate <= endDate;
    });
    setAllOrders(() => filtered);
  };

  const handleOpenOrder = (id) => {
    navigate(`/restaurants/${orgId}/orders/${id}/orderdetails`);
  };

  const handleCloseRestaurant = () => {
    navigate('/restaurants');
    dispatch({
      type: 'GET_SELECTED_RESTAURANT_ID',
      payload: '',
    });
  };
  const handleCloseOrganization = () => {
    navigate(`/restaurants/${orgId}`);
  };
  const handleCloseCustomers = () => {
    setAllOrders('');
    navigate(`/restaurants/${orgId}/customers`);
  };
  const handleCheckResponse = (surveyId, attemptId, orderNumber) => {
    setIsCheckResponseOpen(true);
    setOrderId(orderNumber);
    dispatch(
      getSurveyAttemptResults(
        `api/survey_results?survey_id=${surveyId}&attempt_id=${attemptId}`
      )
    );
  };
  const handleOpenQueueActivity = (queueActivityId) => {
    setIsQueueActivityOpen(true);
    const queueActivity = _.filter(
      allCustomerQueueActivity?.customer_queues,
      function (o) {
        return o.id === queueActivityId;
      }
    );
    setQueueActivityData(queueActivity);
  };
  const handleCloseResponse = () => {
    setIsCheckResponseOpen(false);
  };
  const Columns =
    isTabChanged === 'orders'
      ? [
          {
            title: 'Order ID',
            dataIndex: 'order_number',
            width: '9%',
            sorter: (a, b) => {
              const numA = parseInt(a?.order_number?.replace(/\D/g, ''), 10);
              const numB = parseInt(b?.order_number?.replace(/\D/g, ''), 10);
              return numA - numB;
            },
            render: (Id, { id, order_number }) => {
              return (
                <Row>
                  <Col style={{ cursor: 'pointer' }}>
                    <Tooltip
                      title={`View ${order_number} Details`}
                      onClick={() => handleOpenOrder(id)}
                    >
                      <Typography>{order_number}</Typography>
                    </Tooltip>
                  </Col>
                </Row>
              );
            },
          },
          {
            title: 'Items',
            dataIndex: 'items',
            width: '12%',
            render: (items) => {
              return (
                <Row>
                  <Col>
                    <Typography style={{ wordSpacing: '-5px' }}>
                      {items.join(', ')}
                    </Typography>
                  </Col>
                </Row>
              );
            },
          },
          {
            title: 'Status',
            dataIndex: 'status',
            width: '10%',
            render: (status, { status_color }) => {
              return (
                <Row>
                  <Col>
                    <Typography
                      className='odrersStatus'
                      style={{ color: status_color }}
                    >
                      <FaCircle className='circleIcon' />
                      {status}
                    </Typography>
                  </Col>
                </Row>
              );
            },
          },
          {
            title: 'Date Created',
            dataIndex: 'order_created_date_time',
            width: '10%',
            sorter: (a, b) => {
              const formatDateTime = (created_date, created_time) => {
                const cleanedTime = created_time.replace(' IST', '');
                return dayjs(
                  `${created_date} ${cleanedTime}`,
                  'DD-MM-YYYY hh:mm A'
                );
              };
              const dateA = formatDateTime(
                a.order_created_date_time.created_date,
                a.order_created_date_time.created_time
              );
              const dateB = formatDateTime(
                b.order_created_date_time.created_date,
                b.order_created_date_time.created_time
              );
              return dateA - dateB;
            },
            render: (order_created_date_time) => {
              return (
                <Row>
                  <Col>
                    <Typography>
                      {order_created_date_time.created_time}
                    </Typography>
                    <Typography>
                      {order_created_date_time.created_date}
                    </Typography>
                  </Col>
                </Row>
              );
            },
          },
          {
            title: 'Order Type',
            dataIndex: 'order_type',
            width: '8%',
            render: (order_type) => {
              return (
                <Row>
                  <Col>
                    <Typography>{order_type}</Typography>
                  </Col>
                </Row>
              );
            },
          },
          {
            title: 'Payment Modes',
            dataIndex: 'payment_modes',
            width: '7%',
            render: (payment_modes) => {
              return (
                <Row>
                  <Col>
                    <Typography style={{ wordSpacing: '-5px' }}>
                      {payment_modes.join(', ')}
                    </Typography>
                  </Col>
                </Row>
              );
            },
          },
          {
            title: 'Bill Amount',
            dataIndex: 'bill_amount',
            width: '6%',
            sorter: (a, b) => a.bill_amount - b.bill_amount,
            render: (bill_amount) => {
              return (
                <Row>
                  <Col>
                    <Typography>
                      <FaIndianRupeeSign fontSize='10px' />
                      {bill_amount}
                    </Typography>
                  </Col>
                </Row>
              );
            },
          },
        ]
      : isTabChanged === 'surveys'
      ? [
          {
            title: 'Order ID',
            dataIndex: 'order_number',
            width: '9%',
            sorter: (a, b) => {
              const numA = parseInt(
                a?.attempt?.order_number?.replace(/\D/g, ''),
                10
              );
              const numB = parseInt(
                b?.attempt?.order_number?.replace(/\D/g, ''),
                10
              );
              return numA - numB;
            },
            render: (Id, { id, attempt }) => {
              return (
                <Row>
                  <Col style={{ cursor: 'pointer' }}>
                    <Tooltip
                      title={`View ${attempt?.order_number} Details`}
                      onClick={() => handleOpenOrder(attempt?.order_id)}
                    >
                      <Typography>{attempt?.order_number}</Typography>
                    </Tooltip>
                  </Col>
                </Row>
              );
            },
          },
          {
            title: 'Survey name',
            dataIndex: 'survey_name',
            width: '10%',
            render: (id, { attempt }) => {
              return (
                <Row>
                  <Col>
                    <Typography>{attempt?.survey_name}</Typography>
                  </Col>
                </Row>
              );
            },
          },
          {
            title: 'Attempted date',
            dataIndex: 'attempted_date',
            width: '8%',
            sorter: (a, b) => {
              const dateFormat = 'DD-MM-YYYY hh:mm A';
              const dateA = dayjs(a?.attempt?.attempted_date, dateFormat);
              const dateB = dayjs(b?.attempt?.attempted_date, dateFormat);
              return dateA - dateB;
            },
            render: (id, { attempt }) => {
              return (
                <Row>
                  <Col>
                    <Typography>{attempt?.attempted_date}</Typography>
                  </Col>
                </Row>
              );
            },
          },
          {
            title: 'Order date',
            dataIndex: 'order_date',
            width: '8%',
            sorter: (a, b) => {
              const dateFormat = 'DD-MM-YYYY hh:mm A';
              const dateA = dayjs(a?.attempt?.order_date, dateFormat);
              const dateB = dayjs(b?.attempt?.order_date, dateFormat);
              return dateA - dateB;
            },
            render: (id, { attempt }) => {
              return (
                <Row>
                  <Col>
                    <Typography>{attempt?.order_date}</Typography>
                  </Col>
                </Row>
              );
            },
          },
          {
            width: '2%',
            render: (id, { attempt }) => {
              return (
                <Row className='centerItem'>
                  <Col>
                    <Typography
                      onClick={() =>
                        handleCheckResponse(
                          attempt?.survey_id,
                          attempt?.attempt_id,
                          attempt?.order_number
                        )
                      }
                    >
                      Check response
                    </Typography>
                  </Col>
                </Row>
              );
            },
          },
        ]
      : isTabChanged === 'queueActivity'
      ? [
          {
            title: 'Queue no',
            dataIndex: 'queue_number',
            width: '7%',
            render: (Id, { id, queue_number }) => {
              return (
                <Row>
                  <Col>
                    <Tooltip title='Open Queue details'>
                      <Typography
                        onClick={() => handleOpenQueueActivity(id)}
                        style={{ cursor: 'pointer' }}
                        className='queueActivityTextWrap'
                      >
                        {queue_number}
                      </Typography>
                    </Tooltip>
                  </Col>
                </Row>
              );
            },
          },
          {
            title: 'Booking type',
            dataIndex: 'booking_type',
            width: '7%',
            render: (booking_type) => {
              return (
                <Row>
                  <Col>
                    <Typography className='queueActivityTextWrap'>
                      {booking_type?.charAt(0).toUpperCase() +
                        booking_type?.slice(1)}
                    </Typography>
                  </Col>
                </Row>
              );
            },
          },
          {
            title: 'Status',
            dataIndex: 'status',
            width: '7%',
            render: (status) => {
              return (
                <Row>
                  <Col>
                    <Typography className='queueActivityTextWrap'>
                      {status?.charAt(0).toUpperCase() + status?.slice(1)}
                    </Typography>
                  </Col>
                </Row>
              );
            },
          },
          {
            title: 'Total serve time',
            dataIndex: 'total_serve_time',
            width: '7%',
            render: (total_serve_time) => {
              return (
                <Row>
                  <Col>
                    <Typography>{total_serve_time}</Typography>
                  </Col>
                </Row>
              );
            },
          },
          {
            title: 'Booking time',
            dataIndex: 'booking_time',
            width: '7%',
            render: (Id, { booking_time, booking_date }) => {
              return (
                <Row>
                  <Col>
                    <Typography className='queueActivityTextWrap'>
                      {booking_time}
                    </Typography>
                    <Typography className='queueActivityTextWrap'>
                      {booking_date}
                    </Typography>
                  </Col>
                </Row>
              );
            },
          },
          {
            title: 'Completed time',
            dataIndex: 'queue_activities',
            width: '7%',
            render: (queue_activities) => {
              return (
                <Row>
                  <Col>
                    <Typography>
                      {queue_activities?.map((activity, i) => {
                        const hasCompleted = queue_activities?.some(
                          (a) => a.activity_type === 'completed'
                        );

                        return hasCompleted
                          ? activity?.activity_type === 'completed'
                            ? moment
                                .utc(activity?.created_at)
                                .tz(organizationTimezone)
                                .format('hh:mm A')
                            : ''
                          : i === 0
                          ? '-'
                          : '';
                      })}
                      {/* {queue_activities?.map((queue) =>
                        queue?.activity_type === 'completed'
                          && moment(queue?.created_at)
                          .tz(organizationTimezone)
                          .format('hh:mm A')
                          
                      )} */}
                    </Typography>
                  </Col>
                </Row>
              );
            },
          },
          {
            title: 'Layout preference',
            dataIndex: 'layout_preference',
            width: '7%',
            render: (layout_preference) => {
              return (
                <Row>
                  <Col>
                    <Typography>{layout_preference}</Typography>
                  </Col>
                </Row>
              );
            },
          },
          {
            title: 'Party size',
            dataIndex: 'party_size',
            width: '5%',
            render: (party_size) => {
              return (
                <Row>
                  <Col>
                    <Typography>{party_size}</Typography>
                  </Col>
                </Row>
              );
            },
          },
        ]
      : '';

  const handleOpenAddressDrawer = () => {
    setIsViewAddressesOpen(true);
  };

  const handleCloseAddressDrawer = () => {
    setIsViewAddressesOpen(false);
  };

  const handleCloseQueueActivityDrawer = () => {
    setIsQueueActivityOpen(false);
  };

  const onChangeTab = (value) => {
    setIsTabChanged(value);
  };

  return (
    <Spin spinning={isCustomerLoading}>
      <Row gutter={[24, 24]}>
        <Col xl={23} lg={23} md={23} sm={23} xs={23}>
          <Breadcrumb separator='>'>
            <Breadcrumb.Item
              onClick={() => handleCloseRestaurant()}
              className='breadcrumRestaurant'
            >
              Restaurants
            </Breadcrumb.Item>
            <Breadcrumb.Item
              onClick={() => handleCloseOrganization()}
              className='breadcrumRestaurant'
            >
              {restaurant?.name}
            </Breadcrumb.Item>
            <Breadcrumb.Item
              onClick={() => handleCloseCustomers()}
              className='breadcrumRestaurant'
            >
              Customers
            </Breadcrumb.Item>
            <Breadcrumb.Item className='breadcrumUsers'>
              {Customer?.phone_with_country_code} | {Customer?.name}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col span={18}>
          <Typography className='heading'>
            {Customer?.phone_with_country_code} | {Customer?.name}
          </Typography>
        </Col>
      </Row>
      <Row gutter={[16, 16]} className='customers'>
        <Col span={4} className='customerDetails'>
          <Typography className='customerData'>Total Spend</Typography>
          <Typography className='subHeading'>
            {organziationCurrencySymbol}
            {Customer?.total_spend}
          </Typography>
        </Col>
        <Col span={4} className='customerDetails'>
          <Typography className='customerData'>Total Orders</Typography>
          <Typography className='subHeading'>
            {Customer?.total_orders}
          </Typography>
        </Col>
        <Col span={4} className='customerDetails'>
          <Typography className='customerData'>Average Order Value</Typography>
          <Typography className='subHeading'>
            {organziationCurrencySymbol}
            {Customer?.average_order_value}
          </Typography>
        </Col>
        <Col span={4} className='customerDetails'>
          <Typography className='customerData'>Surveys Completed</Typography>
          <Typography className='subHeading'>
            {Customer?.surveys_complated}
          </Typography>
        </Col>
        <Col span={4}>
          <Typography className='customerData'>Average Order Type</Typography>
          <Typography className='subHeading'>
            {Customer?.average_order_type}
          </Typography>
        </Col>
        <Col span={1}>
          <Typography style={{ marginTop: '10px' }}>
            <img src={foodRestaurant} />{' '}
          </Typography>
          <Typography style={{ marginTop: '8px' }}>
            <img src={takeAway} />
          </Typography>
        </Col>
        <Col span={4} style={{ marginTop: '20px' }}>
          <Typography className='customerData'>Customer Retention</Typography>
          <Typography className='subHeading'>
            {Customer?.customer_retention?.yearly}%
          </Typography>
        </Col>
      </Row>
      <Row gutter={[12, 12]} style={{ marginTop: '15px' }}>
        <Col span={24}>
          <Typography className='customerInfoHeading'>
            Customer Information
          </Typography>
        </Col>
        <Col span={6}>
          <Row className='customerInformation'>
            <Col span={24}>
              <FaRegCircleUser
                style={{ fontSize: '30px', marginBottom: '10px' }}
              />
              <Typography className='customerHeading'>Name</Typography>
              <Typography className='customerData'>{Customer?.name}</Typography>
            </Col>
            <Col span={24}>
              <Typography className='customerHeading'>Email </Typography>
              <Typography className='customerData'>
                {Customer?.email ? Customer?.email : '-'}
              </Typography>
            </Col>
            <Col span={24}>
              <Typography className='customerHeading'>Phone</Typography>
              <Typography className='customerData'>
                {Customer?.phone_with_country_code}
              </Typography>
            </Col>
            <Col span={24}>
              <Typography className='customerHeading'>First Visited</Typography>
              <Typography className='customerData'>
                {Customer?.first_visited}
              </Typography>
            </Col>
            <Col span={24}>
              <Typography className='customerHeading'>Relation</Typography>
              <Typography className='customerData'>
                {Customer?.relation}
              </Typography>
            </Col>
            <Col span={15}>
              <Button className='orderButton' onClick={handleOpenAddressDrawer}>
                View addresses
              </Button>
            </Col>
          </Row>
        </Col>
        <Col span={18} style={{ textAlign: 'left' }}>
          <Row gutter={[12, 12]}>
            <Col span={12}>
              <Tabs
                activeKey={isTabChanged}
                className='customization-tab'
                type='card'
                onChange={onChangeTab}
                items={[
                  {
                    label: `Orders`,
                    key: 'orders',
                  },
                  {
                    label: `Surveys`,
                    key: 'surveys',
                  },
                  {
                    label: `Queue activity`,
                    key: 'queueActivity',
                  },
                ]}
              />
            </Col>
            {/* <Col span={8}>
            <input />
            </Col> */}
            <Col span={12}>
              <Row justify='end'>
                <Col>
                  <DateRangeSelector
                    selectionRange={selectionRange}
                    onChange={handleSelect}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          {isTabChanged === 'orders' ? (
            <Row className='ordersTable'>
              <Col span={24}>
                <Spin spinning={isCustomerOrdersLoading}>
                  <CustomTable columns={Columns} data={allOrders} />
                  {allCustomerOrders.customer_orders && length > 0 ? (
                    <Col span={24}>
                      <Row justify='center' style={{ margin: 30 }}>
                        <Pagination
                          total={allCustomerOrders?.length}
                          onChange={(e) => setOrderPage(e)}
                          responsive={true}
                          size='large'
                          current={orderPage}
                          showSizeChanger={false}
                        />
                      </Row>
                    </Col>
                  ) : (
                    ''
                  )}
                </Spin>
              </Col>
            </Row>
          ) : isTabChanged === 'surveys' ? (
            <Row className='ordersTable'>
              <Col span={24}>
                <Spin spinning={isCustomerSurveysLoading}>
                  <CustomTable
                    columns={Columns}
                    data={allCustomerSurveys?.customer_surveys}
                  />
                  {allCustomerSurveys?.customer_surveys && length > 0 ? (
                    <Col span={24}>
                      <Row justify='center' style={{ margin: 30 }}>
                        <Pagination
                          total={allCustomerSurveys?.length}
                          onChange={(e) => setSurveyPage(e)}
                          responsive={true}
                          size='large'
                          current={surveyPage}
                          showSizeChanger={false}
                        />
                      </Row>
                    </Col>
                  ) : (
                    ''
                  )}
                </Spin>
              </Col>
            </Row>
          ) : isTabChanged === 'queueActivity' ? (
            <Row className='ordersTable'>
              <Col span={24}>
                <Spin spinning={isCustomerQueueActivityLoading}>
                  <CustomTable
                    columns={Columns}
                    data={allCustomerQueueActivity?.customer_queues}
                  />
                  {allCustomerQueueActivity?.customer_queues && length > 0 ? (
                    <Col span={24}>
                      <Row justify='center' style={{ margin: 30 }}>
                        <Pagination
                          total={allCustomerQueueActivity?.length}
                          onChange={(e) => setSurveyPage(e)}
                          responsive={true}
                          size='large'
                          current={queueActivityPage}
                          showSizeChanger={false}
                        />
                      </Row>
                    </Col>
                  ) : (
                    ''
                  )}
                </Spin>
              </Col>
            </Row>
          ) : (
            'No data'
          )}

          <Drawer
            closeIcon={<img src={Back} alt='props' />}
            className='customerAddressHeader'
            closable={false}
            title={
              <Row>
                <Col span={2}>
                  <img
                    src={Back}
                    alt='props'
                    onClick={handleCloseAddressDrawer}
                    style={{ cursor: 'pointer' }}
                  />
                </Col>
                <Col span={22}>
                  <Typography className='drawerHeading'>
                    Saved addresses
                  </Typography>
                </Col>
                {/* <Col span={2}>
                    <CloseOutlined
                      onClick={handleCloseAddressDrawer}
                      style={{ cursor: 'pointer' }}
                    />
                  </Col> */}
              </Row>
            }
            placement='right'
            open={isViewAddressesOpen}
            onClose={handleCloseAddressDrawer}
          >
            <Row>
              {Customer?.user_addresses?.map((address, i) => {
                const fullAddress = [
                  address?.address_line_1,
                  address?.address_line_2,
                  address?.landmark,
                  address?.other_location_detail,
                  address?.city,
                  address?.zip_code,
                ]
                  .filter(Boolean)
                  .join(', ');
                return (
                  <Col span={24} key={i}>
                    <Row gutter={[4, 4]}>
                      <Col span={24}>
                        <Typography className='customerAddressType'>
                          {address.address_type}
                        </Typography>
                      </Col>
                      <Col span={24}>
                        <Typography className='customerFullAddress'>
                          {fullAddress}
                        </Typography>
                      </Col>
                    </Row>
                  </Col>
                );
              })}
            </Row>
          </Drawer>

          <Drawer
            closeIcon={<img src={Back} alt='props' />}
            className='customerAddressHeader'
            closable={false}
            title={
              <>
                <Row>
                  <Col span={2}>
                    <img
                      src={Back}
                      alt='props'
                      onClick={handleCloseResponse}
                      style={{ cursor: 'pointer' }}
                    />
                  </Col>
                  <Col span={22}>
                    <Typography className='drawerHeading'>
                      Preview survey response
                    </Typography>
                  </Col>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                  <Col span={24}>
                    <Typography className='detailsubheading'>
                      Order ID
                    </Typography>
                    <Typography className='detailsubheading'>
                      {orderId}
                    </Typography>
                  </Col>
                </Row>
              </>
            }
            placement='right'
            onClose={handleCloseResponse}
            open={isCheckResponseOpen}
            // width={width > 400 ? '500px' : '100%'}
          >
            {
              <Row>
                <Col span={24} style={{ marginTop: '20px' }}>
                  <Row gutter={[16, 16]}>
                    {allSurveyAttemptResult?.map((result) =>
                      result?.question_type ===
                      'Rapidfire::Questions::Checkbox' ? (
                        <Col span={24}>
                          <Typography className='detailsubheading'>
                            {result?.question_text}
                          </Typography>
                          {Object.entries(result.results).map(
                            ([option, checked], idx) => (
                              <Checkbox
                                key={idx}
                                checked={Boolean(checked)}
                                className='detailsubheading'
                              >
                                {option}
                              </Checkbox>
                            )
                          )}
                        </Col>
                      ) : result?.question_type ===
                        'Rapidfire::Questions::Date' ? (
                        <Col span={24}>
                          <Typography className='detailsubheading'>
                            {result?.question_text}
                          </Typography>
                          <DatePicker
                            size='large'
                            value={dayjs(result?.results[0])} // Convert string to dayjs object
                            format='YYYY-MM-DD'
                            style={{
                              borderRadius: '5px',
                              ...readOnlyStyle,
                            }}
                            className='detailsubheading'
                          />
                        </Col>
                      ) : result?.question_type ===
                        'Rapidfire::Questions::Long' ? (
                        <Col span={24}>
                          <Typography className='detailsubheading'>
                            {result?.question_text}
                          </Typography>
                          <TextArea
                            size='large'
                            className='inputLabel'
                            value={result?.results?.toString()}
                            style={{
                              fontFamily: 'Circular-400',
                              wordSpacing: '-4px',
                              ...readOnlyStyle,
                            }}
                          />
                        </Col>
                      ) : result?.question_type ===
                        'Rapidfire::Questions::Numeric' ? (
                        <Col span={24}>
                          <Typography className='detailsubheading'>
                            {result?.question_text}
                          </Typography>
                          <Rate
                            className='customRate'
                            value={result?.results.toString()}
                            disabled
                          />
                        </Col>
                      ) : result?.question_type ===
                        'Rapidfire::Questions::Radio' ? (
                        <Col span={24}>
                          <Typography className='detailsubheading'>
                            {result?.question_text}
                          </Typography>
                          <Radio.Group
                            defaultValue={
                              Object.entries(result.results).find(
                                ([, value]) => value === 1
                              )?.[0] || null
                            }
                            style={readOnlyStyle}
                          >
                            {Object.entries(result.results).map(
                              ([option, selected], idx) => (
                                <Radio
                                  key={idx}
                                  value={option}
                                  className='detailsubheading'
                                >
                                  {option}
                                </Radio>
                              )
                            )}
                          </Radio.Group>
                        </Col>
                      ) : result?.question_type ===
                        'Rapidfire::Questions::Select' ? (
                        <Col span={12}>
                          <Typography className='detailsubheading'>
                            {result?.question_text}
                          </Typography>
                          <Select
                            defaultValue={
                              Object.entries(result.results).find(
                                ([, value]) => value === 1
                              )?.[0] || undefined
                            }
                            style={{ width: '100%', ...readOnlyStyle }}
                            className='detailsubheading'
                          >
                            {Object.entries(result.results).map(
                              ([option, selected], idx) => (
                                <Select.Option key={idx} value={option}>
                                  {option}
                                </Select.Option>
                              )
                            )}
                          </Select>
                        </Col>
                      ) : result?.question_type ===
                        'Rapidfire::Questions::Short' ? (
                        <Col span={24}>
                          <Typography className='detailsubheading'>
                            {result?.question_text}
                          </Typography>
                          <Input
                            size='large'
                            className='inputLabel'
                            value={result?.results.toString()}
                            style={{
                              fontFamily: 'Circular-400',
                              wordSpacing: '-4px',
                              ...readOnlyStyle,
                            }}
                          />
                        </Col>
                      ) : result?.question_type ===
                        'Rapidfire::Questions::Information' ? (
                        <Col span={24}>
                          <Typography className='detailsubheading'>
                            {result?.question_text}
                          </Typography>
                        </Col>
                      ) : null
                    )}
                  </Row>
                </Col>
              </Row>
            }
          </Drawer>

          <Drawer
            closeIcon={<img src={Back} alt='props' />}
            className='customerAddressHeader'
            closable={false}
            title={
              <Row>
                <Col span={2}>
                  <img
                    src={Back}
                    alt='props'
                    onClick={handleCloseQueueActivityDrawer}
                    style={{ cursor: 'pointer' }}
                  />
                </Col>
                <Col span={22}>
                  <Typography className='drawerHeading'>
                    {queueActivityData[0]?.queue_number || '-'}
                  </Typography>
                </Col>
              </Row>
            }
            placement='right'
            open={isQueueActivityOpen}
            onClose={handleCloseQueueActivityDrawer}
            width='40%'
          >
            <Row>
              <Col span={24}>
                <Row>
                  <Col span={24}>
                    <Row justify='center'>
                      <Col className='ququeButtonContainer'>
                        <Button className='detailsButton'>Go to Queue</Button>
                      </Col>
                    </Row>

                    <Row className='queueActivityContainer'>
                      <Col span={24}>
                        <Row>
                          <Col span={24} className='centerText'>
                            <MdAccessTimeFilled fontSize='large' />
                          </Col>
                          <Divider className='queueActivityDivider' />
                          {queueActivityData[0]?.status === 'cancelled' ? (
                            <Col span={24}>
                              {queueActivityData[0]?.queue_activities?.map(
                                (queue, i) => {
                                  return queue?.activity_type ===
                                    'cancelled' ? (
                                    <>
                                      <Row
                                        key={i}
                                        className='queueActivitySpace'
                                      >
                                        <Col span={24} className='centerText'>
                                          <Typography className='queueActivityText'>
                                            Cancellation time
                                          </Typography>
                                        </Col>
                                        <Col span={24} className='centerText'>
                                          <Typography className='queueActivitydetails'>{`${moment
                                            .utc(queue?.created_at)
                                            .tz(organizationTimezone)
                                            .format('hh:mm A')} | ${moment(
                                            queue?.created_at
                                          ).format('DD/MM/YYYY')}`}</Typography>
                                        </Col>
                                      </Row>

                                      <Row>
                                        <Col span={12}>
                                          <Row>
                                            <Col
                                              span={24}
                                              className='centerText'
                                            >
                                              <Typography className='queueActivityText'>
                                                {queueActivityData[0]
                                                  ?.booking_type ===
                                                'reservation'
                                                  ? 'Booking time'
                                                  : 'Arrival time'}
                                              </Typography>
                                            </Col>
                                            <Col
                                              span={24}
                                              className='centerText'
                                            >
                                              <Typography className='queueActivitydetails'>
                                                {`${queueActivityData[0]?.booking_time} | ${queueActivityData[0]?.booking_date}`}
                                              </Typography>
                                            </Col>
                                          </Row>
                                        </Col>
                                        <Col span={12}>
                                          <Row>
                                            <Col
                                              span={24}
                                              className='centerText'
                                            >
                                              <Typography className='queueActivityText'>
                                                {queueActivityData[0]
                                                  ?.booking_type ===
                                                'reservation'
                                                  ? 'Reservation time'
                                                  : 'Estimated wait time'}
                                              </Typography>
                                            </Col>
                                            <Col
                                              span={24}
                                              className='centerText'
                                            >
                                              <Typography className='queueActivitydetails'>
                                                {queueActivityData[0]
                                                  ?.booking_type ===
                                                'reservation' ? (
                                                  `${moment(
                                                    queueActivityData[0]
                                                      ?.reservation_time,
                                                    'HH:mm'
                                                  ).format('hh:mm A')} | ${
                                                    queueActivityData[0]
                                                      ?.reservation_date
                                                  }`
                                                ) : (
                                                  <div>
                                                    {moment
                                                      .tz(
                                                        queueActivityData[0]
                                                          ?.reservation_time,
                                                        'hh:mm A',
                                                        organizationTimezone
                                                      )
                                                      .diff(
                                                        moment.tz(
                                                          queueActivityData[0]
                                                            ?.booking_time,
                                                          'hh:mm A',
                                                          organizationTimezone
                                                        ),

                                                        'minutes'
                                                      )}{' '}
                                                    mins (
                                                    {
                                                      <span
                                                        style={{
                                                          color: '#7b7b7b',
                                                        }}
                                                      >
                                                        {moment
                                                          .tz(
                                                            queueActivityData[0]
                                                              ?.reservation_time,
                                                            'HH:mm',
                                                            organizationTimezone
                                                          )
                                                          .format('hh:mm A')}
                                                      </span>
                                                    }
                                                    )
                                                  </div>
                                                )}
                                              </Typography>
                                            </Col>
                                          </Row>
                                        </Col>
                                      </Row>
                                    </>
                                  ) : (
                                    ''
                                  );
                                }
                              )}
                            </Col>
                          ) : queueActivityData[0]?.status === 'completed' ? (
                            <>
                              <Col span={24}>
                                <Row style={{ paddingTop: '20px' }}>
                                  {queueActivityData[0]?.queue_activities?.map(
                                    (queue, i) => {
                                      const isLastItem =
                                        queue?.activity_type === 'arrived' ||
                                        queue?.activity_type === 'completed'
                                          ? i ===
                                            queueActivityData[0]
                                              ?.queue_activities?.length -
                                              1
                                          : false;
                                      const queueTimeHeading =
                                        queue?.activity_type === 'arrived'
                                          ? 'Arrival time'
                                          : queue?.activity_type === 'completed'
                                          ? 'Completed time'
                                          : '';

                                      const queueTime = `${moment(
                                        queue?.created_at
                                      ).format('hh:mm A')} | ${moment(
                                        queue?.created_at
                                      ).format('DD/MM/YYYY')}`;

                                      return (
                                        (queue?.activity_type === 'arrived' ||
                                          queue?.activity_type ===
                                            'completed') && (
                                          <Col span={24} className='centerText'>
                                            <Timeline className='customTimeline'>
                                              <Timeline.Item
                                                dot={
                                                  <div
                                                    style={{
                                                      width: '10px',
                                                      height: '10px',
                                                      borderRadius: '50%',
                                                      backgroundColor:
                                                        '#262626',
                                                    }}
                                                  ></div>
                                                }
                                                className={`${
                                                  isLastItem
                                                    ? 'hideTail last-timeline-item'
                                                    : ''
                                                }`}
                                              >
                                                <Typography className='queueActivitydetails'>
                                                  {queueTimeHeading}
                                                </Typography>
                                                <Typography className='queueActivitydetails'>
                                                  {queueTime}
                                                </Typography>
                                              </Timeline.Item>
                                            </Timeline>
                                          </Col>
                                        )
                                      );
                                    }
                                  )}
                                </Row>
                                <Divider className='queueActivityDivider' />
                                <Row>
                                  <Col span={12}>
                                    <Row>
                                      <Col span={24} className='centerText'>
                                        <Typography className='queueActivityText'>
                                          {queueActivityData[0]
                                            ?.booking_type === 'reservation'
                                            ? 'Created time'
                                            : 'Total wait time'}
                                        </Typography>
                                      </Col>
                                      <Col span={24} className='centerText'>
                                        <Typography className='queueActivitydetails'>
                                          {queueActivityData[0]
                                            ?.booking_type === 'reservation' ? (
                                            `${queueActivityData[0]?.booking_time} | ${queueActivityData[0]?.booking_date}`
                                          ) : queueActivityData[0]
                                              ?.queue_activities?.length > 1 ? (
                                            <div
                                              style={{
                                                color: '#eb5757',
                                              }}
                                            >
                                              {moment(
                                                queueActivityData[0]
                                                  ?.queue_activities[
                                                  queueActivityData[0]
                                                    ?.queue_activities.length -
                                                    1
                                                ]?.created_at
                                              ).diff(
                                                moment(
                                                  queueActivityData[0]
                                                    ?.queue_activities[0]
                                                    ?.created_at
                                                ),
                                                'minutes'
                                              )}{' '}
                                              mins
                                            </div>
                                          ) : null}
                                        </Typography>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col span={12}>
                                    <Row>
                                      <Col span={24} className='centerText'>
                                        <Typography className='queueActivityText'>
                                          {queueActivityData[0]
                                            ?.booking_type === 'reservation'
                                            ? 'Reservation time'
                                            : 'Estimated wait time'}
                                        </Typography>
                                      </Col>
                                      <Col span={24} className='centerText'>
                                        <Typography className='queueActivitydetails'>
                                          {queueActivityData[0]
                                            ?.booking_type === 'reservation' ? (
                                            `${moment(
                                              queueActivityData[0]
                                                ?.reservation_time,
                                              'HH:mm'
                                            ).format('hh:mm A')} | ${
                                              queueActivityData[0]
                                                ?.reservation_date
                                            }`
                                          ) : (
                                            <div>
                                              {moment
                                                .tz(
                                                  queueActivityData[0]
                                                    ?.reservation_time,
                                                  'hh:mm A',
                                                  organizationTimezone
                                                )
                                                .diff(
                                                  moment.tz(
                                                    queueActivityData[0]
                                                      ?.booking_time,
                                                    'hh:mm A',
                                                    organizationTimezone
                                                  ),
                                                  'minutes'
                                                )}{' '}
                                              mins{' '}
                                              {
                                                <span
                                                  style={{ color: '#7b7b7b' }}
                                                >
                                                  (
                                                  {moment
                                                    .tz(
                                                      queueActivityData[0]
                                                        ?.reservation_time,
                                                      'HH:mm',
                                                      organizationTimezone
                                                    )
                                                    .format('hh:mm A')}
                                                  )
                                                </span>
                                              }
                                            </div>
                                          )}
                                        </Typography>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Col>
                            </>
                          ) : (
                            ''
                          )}
                        </Row>
                      </Col>
                    </Row>
                    <Row
                      style={{ marginTop: '15px' }}
                      className='queueActivityContainer'
                    >
                      <Col span={24}>
                        <Row>
                          <Col span={24} className='centerText'>
                            <IoMdPerson fontSize='large' />
                          </Col>
                          <Divider className='queueActivityDivider' />
                          <Col span={24}>
                            <Row className='queueActivitySpace'>
                              <Col span={24} className='centerText'>
                                <Typography className='queueActivityText'>
                                  Customer name
                                </Typography>
                              </Col>
                              <Col span={24} className='centerText'>
                                <Typography className='queueActivitydetails'>
                                  {Customer?.name || '-'}
                                </Typography>
                              </Col>
                            </Row>

                            <Row className='queueActivitySpace'>
                              <Col span={12}>
                                <Row>
                                  <Col span={24} className='centerText'>
                                    <Typography className='queueActivityText'>
                                      Phone no
                                    </Typography>
                                  </Col>
                                  <Col span={24} className='centerText'>
                                    <Typography className='queueActivitydetails'>
                                      {Customer?.phone_with_country_code}
                                    </Typography>
                                  </Col>
                                </Row>
                              </Col>
                              <Col span={12}>
                                <Row>
                                  <Col span={24} className='centerText'>
                                    <Typography className='queueActivityText'>
                                      Party size
                                    </Typography>
                                  </Col>
                                  <Col span={24} className='centerText'>
                                    <Typography className='queueActivitydetails'>
                                      {queueActivityData[0]?.party_size}
                                    </Typography>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>

                            <Row className='queueActivitySpace'>
                              <Col span={12}>
                                <Row>
                                  <Col span={24} className='centerText'>
                                    <Typography className='queueActivityText'>
                                      Booking type
                                    </Typography>
                                  </Col>
                                  <Col span={24} className='centerText'>
                                    <Typography className='queueActivitydetails'>
                                      {queueActivityData[0]?.booking_type
                                        ?.charAt(0)
                                        .toUpperCase() +
                                        queueActivityData[0]?.booking_type?.slice(
                                          1
                                        )}
                                    </Typography>
                                  </Col>
                                </Row>
                              </Col>
                              <Col span={12} className='centerText'>
                                <Row>
                                  <Col span={24} className='centerText'>
                                    <Typography className='queueActivityText'>
                                      Booking no
                                    </Typography>
                                  </Col>
                                  <Col span={24} className='centerText'>
                                    <Typography className='queueActivitydetails'>
                                      {queueActivityData[0]?.queue_number}
                                    </Typography>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>

                            {queueActivityData[0]?.status === 'cancelled' ? (
                              <Row>
                                <Col span={24} className='centerText'>
                                  <Typography className='queueActivityText'>
                                    Cancellation reason
                                  </Typography>
                                </Col>
                                <Col span={24} className='centerText'>
                                  <Typography className='queueActivitydetails'>
                                    {queueActivityData[0]?.reason || '-'}
                                  </Typography>
                                </Col>
                              </Row>
                            ) : (
                              ''
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row
                      style={{ marginTop: '15px' }}
                      className='queueActivityContainer'
                    >
                      <Col span={24}>
                        <Row>
                          <Col span={24} className='centerText'>
                            <MdTableRestaurant fontSize='large' />
                          </Col>

                          <Divider className='queueActivityDivider' />

                          {queueActivityData[0]?.status === 'cancelled' ? (
                            <Col span={24}>
                              <Row className='queueActivitySpace'>
                                <Col span={24} className='centerText'>
                                  <Typography className='queueActivityText'>
                                    Layout Preferrence
                                  </Typography>
                                </Col>
                                <Col span={24} className='centerText'>
                                  <Typography className='queueActivitydetails'>
                                    {queueActivityData[0]?.layout_preference}
                                  </Typography>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={24} className='centerText'>
                                  <Typography className='queueActivityText'>
                                    Extras
                                  </Typography>
                                </Col>
                                <Col span={24} className='centerText'>
                                  {queueActivityData[0]?.queue_table ? (
                                    <div
                                      style={{ display: 'flex', gap: '10px' }}
                                    >
                                      {queueActivityData[0].queue_table
                                        .kids_seat_availability && (
                                        <img src={kidsChair} alt='kidsChair' />
                                      )}
                                      {queueActivityData[0].queue_table
                                        .barbeque_grill_availability && (
                                        <img src={bbqGrill} alt='props' />
                                      )}
                                      {queueActivityData[0].queue_table
                                        .disabled_seat_availability && (
                                        <img src={disabledSeat} alt='props' />
                                      )}
                                    </div>
                                  ) : (
                                    '-'
                                  )}
                                </Col>
                              </Row>
                            </Col>
                          ) : queueActivityData[0]?.status === 'completed' ? (
                            <Col span={24}>
                              <Row className='queueActivitySpace'>
                                <Col span={24} className='centerText'>
                                  <Typography className='queueActivityText'>
                                    Average waiting time
                                  </Typography>
                                </Col>
                                <Col span={24} className='centerText'>
                                  <Typography className='queueActivitydetails'>
                                    Average waiting time
                                  </Typography>
                                </Col>
                              </Row>

                              <Row className='queueActivitySpace'>
                                <Col span={12}>
                                  <Row>
                                    <Col span={24} className='centerText'>
                                      <Typography className='queueActivityText'>
                                        Layout
                                      </Typography>
                                    </Col>
                                    <Col span={24} className='centerText'>
                                      <Typography className='queueActivitydetails'>
                                        {
                                          queueActivityData[0]?.queue_layout
                                            ?.layout_name
                                        }
                                      </Typography>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col span={12}>
                                  <Row>
                                    <Col span={24} className='centerText'>
                                      <Typography className='queueActivityText'>
                                        Table no
                                      </Typography>
                                    </Col>
                                    <Col span={24} className='centerText'>
                                      <Typography className='queueActivitydetails'>
                                        {queueActivityData[0]?.queue_table
                                          ?.table_number || '-'}
                                      </Typography>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>

                              <Row className='queueActivitySpace'>
                                <Col span={12}>
                                  <Row>
                                    <Col span={24} className='centerText'>
                                      <Typography className='queueActivityText'>
                                        Capacity
                                      </Typography>
                                    </Col>
                                    <Col span={24} className='centerText'>
                                      <Typography className='queueActivitydetails'>
                                        {queueActivityData[0]?.queue_table
                                          ?.seating_capacity || '-'}
                                      </Typography>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col span={12}>
                                  <Row>
                                    <Col span={24} className='centerText'>
                                      <Typography className='queueActivityText'>
                                        Status
                                      </Typography>
                                    </Col>
                                    <Col span={24} className='centerText'>
                                      <Typography className='queueActivitydetails'>
                                        {queueActivityData[0]?.queue_table
                                          ?.is_block
                                          ? 'Blocked'
                                          : queueActivityData[0]?.queue_table
                                              ?.current_order_id
                                          ? 'Busy'
                                          : 'Available'}
                                      </Typography>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={24} className='centerText'>
                                  <Typography className='queueActivityText'>
                                    Extras
                                  </Typography>
                                </Col>
                                <Col span={24} className='centerText'>
                                  {queueActivityData[0]?.queue_table ? (
                                    <div
                                      style={{ display: 'flex', gap: '10px' }}
                                    >
                                      {queueActivityData[0].queue_table
                                        .kids_seat_availability && (
                                        <img src={kidsChair} alt='kidsChair' />
                                      )}
                                      {queueActivityData[0].queue_table
                                        .barbeque_grill_availability && (
                                        <img src={bbqGrill} alt='props' />
                                      )}
                                      {queueActivityData[0].queue_table
                                        .disabled_seat_availability && (
                                        <img src={disabledSeat} alt='props' />
                                      )}
                                    </div>
                                  ) : (
                                    '-'
                                  )}
                                </Col>
                              </Row>
                            </Col>
                          ) : (
                            ''
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Drawer>
        </Col>
      </Row>
    </Spin>
  );
};

export default CustomerDetails;
